import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
// import { _localizeField } from "../core/utils"
// import clsx from "clsx"

const sanityConfig = { projectId: "32wlp8bb", dataset: "production" };

const SanityImage = ({ input, caption, cover }) => {
  // const [imageLoaded, setImageloaded] = useState(false)
  // return null;
  const { asset, alt } = input;
  const { metadata } = asset;
  const imageData = getGatsbyImageData(asset, { maxWidth: 1700 }, sanityConfig);
  // console.log(metadata);
  // useEffect(() => {}, []);

  return (
    <figure className={caption ? "has-caption" : "no-caption"}>
      {/* <div
        className="absolute bg-placeholder"
        style={{
          backgroundColor: metadata.palette.dominant.background,
        }}
      ></div> */}
      <GatsbyImage
        image={imageData}
        alt={alt ? alt : ""}
        style={
          {
            // maxWidth: "calc(100vw - 20px)",
            // maxHeight: "calc(100vh - 100px)",
          }
        }
        imgStyle={{
          backgroundColor: metadata.palette.dominant.background,
          // objectFit: cover ? cover : "unset",
        }}
        // objectFit="contain"

        // onLoad={() => {
        //   setImageloaded(true)
        // }}
      />
      {caption && <figcaption className="text-sm">{caption}</figcaption>}
    </figure>
  );
};

export default SanityImage;
