import React from "react";
import PortableText from "@sanity/block-content-to-react";
import SanityImage from "./SanityImage";
const SanityTexte = ({ input }) => {
  // console.log(input);
  const serializers = {
    types: {
      block(props) {
        // console.log(props)
        switch (props.node.style) {
          // case "h1":
          //   return <h1>{props.children}</h1>
          default:
            return <p>{props.children}</p>;
        }
      },
      image: ({ node }) => {
        // console.log(node);
        // return "image";
        return <SanityImage input={node} />;
      },
    },
    marks: {
      sup: ({ children }) => <sup>{children}</sup>,

      link: ({ mark, children }) => {
        // Read https://css-tricks.com/use-target_blank/
        // console.log(mark);
        const { blank, href } = mark;
        return blank ? (
          <a href={href} target="_blank" rel="noopener, noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
    },
  };
  // console.log(input)
  return (
    <div className="texte">
      <PortableText blocks={input} serializers={serializers} />
    </div>
  );
};

export default SanityTexte;
