import React, { useEffect } from "react";
// import { useStaticQuery, graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";

const WrapperContext = React.createContext();

// const query = graphql`
//   query {
//     settings: prismicSettings {
//       ...settings
//     }
//   }
// `;

const Layout = ({ children, pageContext: { isHome } }) => {
  // const { settings } = useStaticQuery(query);
  // console.log(pageContext);
  const settings = {};
  // console.log(settings)

  useEffect(() => {
    _format();
    window.addEventListener("resize", _format);
    // if (window.innerWidth < 1080 && "ontouchstart" in window) setIsMobile(true)

    return () => {
      window.removeEventListener("resize", _format);
    };
  }, []);

  const _format = () => {
    // console.log("format");
    // const ww = window.innerWidth;
    // const wh = window.innerHeight;
    document.documentElement.style.setProperty(
      "--appHeight",
      window.innerHeight + "px"
    );

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect();

    document.documentElement.style.setProperty(
      "--headerHeight",
      headerBounding.height + "px"
    );
  };

  return (
    <WrapperContext.Provider value={{ settings, isHome }}>
      <div id="page">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </WrapperContext.Provider>
  );
};

export { WrapperContext, Layout };
