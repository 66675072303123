import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
// import clsx from "clsx";
import SanityTexte from "./SanityTexte";
import villaGlovettesLogo from "../images/villa-glovettes-logo-bn-nb.svg";

const query = graphql`
  query {
    sanityFooter {
      links {
        ... on SanityInternalLink {
          _type
          label
          page: link {
            slug {
              current
            }
          }
        }
        ... on SanityExternalLink {
          _type
          label
          link
        }
      }
      social
      contact: _rawContact
    }
  }
`;

const Footer = () => {
  const { sanityFooter } = useStaticQuery(query);
  const { links, social, contact } = sanityFooter;
  // console.log(contact);
  const _getIconByUrl = (url) => {
    const { hostname } = new URL(url);
    if (hostname.indexOf("instagram") > -1) return "instagram";
    else if (hostname.indexOf("facebook") > -1) return "facebook";
    else if (hostname.indexOf("twitter") > -1) return "twitter";
    else return "";
  };

  return (
    <footer className="p-_md md:p-md">
      <div className="container">
        <ul className="links md:text-lg">
          {links.map((li, i) => (
            <li key={i}>
              {li._type === "internalLink" ? (
                <Link to={`/${li.page.slug.current}`}>
                  <span className="mr-sm-">{li.label}</span>{" "}
                  <span className="arrow-se">&#8627;</span>
                </Link>
              ) : (
                <a href={li.link} target="_blank" rel="nooponer, noreferrer">
                  <span className="mr-sm-">{li.label}</span>{" "}
                  <span className="arrow-se">&#8627;</span>
                </a>
              )}
            </li>
          ))}
        </ul>
        <ul className="social flex ">
          {social.map((url, i) => (
            <li key={i} className="pr-sm text-lg">
              <a href={url} target="_blank" rel="nooponer, noreferrer">
                <i className={`icon-${_getIconByUrl(url)}`}></i>
              </a>
            </li>
          ))}
        </ul>
        <div className="flex flex-col md:flex-row justify-between md:items-end ">
          <div className="contacts mb-_lg md:mb-0">
            <SanityTexte input={contact} />
          </div>
          <img
            className="logo"
            src={villaGlovettesLogo}
            alt="villa-glovettes logo"
            width="200"
            height="26"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
