import React from "react";
import { Link } from "gatsby";
// import AniLink from "gatsby-plugin-transition-link/AniLink";
// import LinkFade from "./ui/TransitionLinks/LinkFade";

const Menu = ({ input }) => (
  <nav className="menu-header ">
    <ul className="flex">
      {input.map((li, i) => (
        <li key={i} className="menu-item ">
          {li._type === "internalLink" ? (
            <Link to={`/${li.page.slug.current}`}>{li.label}</Link>
          ) : (
            <a href={li.link} target="_blank" rel="nooponer, noreferrer">
              {li.label}
            </a>
          )}
        </li>
      ))}
    </ul>
  </nav>
);
export default Menu;
