import React, { useEffect, useContext, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import clsx from "clsx";
import PubSub from "pubsub-js";
import Menu from "./Menu";
// import Burger from "./ui/Burger";
import { WrapperContext } from "./Layout";

const query = graphql`
  query {
    sanityHeader {
      siteTitle
      nav {
        items {
          ... on SanityInternalLink {
            _type
            label
            page: link {
              slug {
                current
              }
            }
          }
          ... on SanityExternalLink {
            _type
            label
            link
          }
        }
      }
    }
  }
`;

const Header = () => {
  const [direction, setDirection] = useState();
  const [reveal, setReveal] = useState(false);
  const { sanityHeader } = useStaticQuery(query);
  const { siteTitle, nav } = sanityHeader;

  const { isHome } = useContext(WrapperContext);
  // console.log("isHome", isHome);

  useEffect(() => {
    _onScroll();
    const token = PubSub.subscribe("ROUTE_UPDATE", _onScroll);
    window.addEventListener("scroll", _onScroll);

    setTimeout(() => setReveal(true), 250);
    return () => {
      PubSub.unsubscribe(token);
      window.removeEventListener("scroll", _onScroll);
    };
  }, [isHome]);

  let _prevScrollTop = 0;
  const _onScroll = () => {
    const { pageYOffset, innerHeight } = window;

    pageYOffset > _prevScrollTop ? setDirection("down") : setDirection("up");

    if (pageYOffset <= 50) {
      setDirection("");
    }
    // console.log(isHome, pageYOffset, innerHeight);
    if (isHome && pageYOffset < innerHeight) {
      setDirection("down");
    }

    _prevScrollTop = window.pageYOffset;
  };

  return (
    <header
      className={clsx("p-_sm md:p-sm", direction, reveal ? "" : "hidden")}
    >
      <div className="flex">
        <div className="site-title">
          <Link to="/">{siteTitle}</Link>
        </div>
        <Menu input={nav.items} />
      </div>
      {/* <Burger /> */}
    </header>
  );
};

export default Header;
